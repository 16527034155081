import $ from 'jquery'

$(document).on('turbolinks:load', function () {
  purchaseOrderTotal()

  $('.order_item_price, .order_item_quantity').on('keyup', function () {
    purchaseOrderTotal()
  })

  $('.quantity_type').on('change', function () {
    purchaseOrderTotal()
  })

  $('.purchase_delivery').on('click', function () {
    $(this).find('.purchase_delivery_list').toggleClass('d-none')
  })

  if ($('.payment_method_value').val() == "paid") {
    $('.payment_method').attr('checked', 'checked');
  }
  else {
    $('.payment_method').removeAttr('checked');
  }

  $('.payment_method').on('click', function () {
    if (this.checked == true ) {
      if(confirm('Are you sure to paid this Purchase Order.')){
        $('.payment_method_value').val("paid")
        $('.submit-purchase-order-payment').submit();
      }
      else{
        location.reload();
      }
    }
    else {
      var role = $('.payment_method_value').data('role')
      if (role == 'super_admin'){
        if (confirm('Are you sure to unpaid this Purchase Order.')) {
          $('.payment_method_value').val("unpaid")
          $('.submit-purchase-order-payment').submit();
        }
        else {
          location.reload();
        }
      }
    }
  })

  if ($('.return_order').val() == "true") {
    $('.return_method').attr('checked', 'checked');
  }
  else {
    $('.return_method').removeAttr('checked');
  }

  $('.return_method').on('click', function () {
    if (this.checked == true ) {
      if(confirm('Are you sure to return this Purchase Order.')){
        $('.return_order').val("true")
        $('.submit-purchase-order-payment').submit();
      }
      else{
        location.reload();
      }
    }
    else {
      $('.return_order').val("false")
    }
  })

  $('.change-product-cost, .order_item_price').on('change', function(){
    if($(this).parent().find('.change-value').val() === 'false'){
      $(this).parent().find('.change-value').val('true')
    }
  })

  $('.productSearchOrderField').on('keyup', function (e) {
  if (e.key === 'Enter' || e.keyCode === 13) {
    $('.displayCheck').removeClass('displayCheck')
    $('.rowDisplay').removeClass('rowDisplay')
    var product_sku_list = $('.productSkuSearch')
    var search_term = $(this).val().toLocaleLowerCase()
    for (var i = 0; i < product_sku_list.length; i++) {
      var product_sku = product_sku_list[i].textContent.toLocaleLowerCase()
      var supplier_sku = product_sku_list[i].closest('tr').querySelector('.supplierSkuSearch').textContent.toLocaleLowerCase()
      if (!product_sku.includes(search_term) && !supplier_sku.includes(search_term)) {
        if (!product_sku_list[i].closest('tr').classList.contains('rowDisplay')) {
          product_sku_list[i].closest('tr').classList.add('d-none')
        }
        if (product_sku_list[i].parentElement.closest('.accordion') && !product_sku_list[i].parentElement.closest('.accordion').classList.contains('displayCheck')) {
          $(product_sku_list[i].parentElement.closest('.accordion')).addClass('d-none')
        }
      }
      else {
        product_sku_list[i].closest('tr').classList.remove('d-none')
        product_sku_list[i].closest('tr').classList.add('rowDisplay')
        $(product_sku_list[i].parentElement.closest('.accordion')).removeClass('d-none')
        $(product_sku_list[i].parentElement.closest('.accordion')).addClass('displayCheck')
      }
    }
  }
})

  $(".clickable-row").click(function () {
    window.location = $(this).data("href");
  });

})
function purchaseOrderTotal() {
  var orderItemPrices = $('.order_item_price');
  var orderItemQuantities = $('.order_item_quantity');
  var quantityTypes = $('.quantity_type');

  if (quantityTypes.length == 0){
    return;
  }
  var sum = 0;
  orderItemPrices.each(function (index) {
    var costPriceId = this.id;
    var quantityId = orderItemQuantities[index].id;
    var quantityType = quantityTypes[index].id;

    var quantity = $("#" + quantityId).val();
    var selected = $("option:selected", $("#" + quantityType)).text();

    var q = 1;
    $("#" + quantityId).closest('tr').find('#quantity_type_hidden option').each(function () {
      if (this.text === selected) {
        q = this.value;
      }
    });

    var cost = $("#" + costPriceId).val();
    quantity *= q;

    var itemTotal = isNaN(quantity * cost) ? 0 : quantity * cost;
    $("#" + quantityId).closest('tr').find('.order_item_total').val(itemTotal.toFixed(2));

    var value = $('.order_item_total')[index].value;
    sum += value === '' ? 0 : parseFloat(value);
  });

  $('.order_total').val(sum.toFixed(2));
}
