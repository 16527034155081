$(document).ready(function () {
  var is_all_selected = true;
  var total_scan = 0;
  $('.scan-product-barcode').on('keydown', function (e) {
    if (e.keyCode == 13) {
      var barcodeValues = []
      var total_order_amount = 0
      var indexOfMatched = 0;
      for (var i = 0; i < $('.barcode-values').length; i++) {
        barcodeValues.push($('.barcode-values')[i].textContent)
        total_order_amount += parseInt($('.ordered-amount')[i].textContent)
      }
      if (barcodeValues.includes($(this).val())) {
        indexOfMatched = barcodeValues.indexOf($(this).val());
        var tr = $('.barcode-values')[indexOfMatched].closest('tr')
        var dispatchElement = tr.querySelector('#dispatched-amount')
        var orderedElement = tr.querySelector('.ordered-amount')
        var totalDispatchElement = $(tr).find(".total-dispatched-amount:first");
        var dipatchAmount = parseInt(dispatchElement.value)
        var orderedAmount = parseInt(orderedElement.textContent)
        var totalDispatchAmount = parseInt(totalDispatchElement.text())
        if ((total_order_amount - totalDispatchAmount >= 1) && (orderedAmount - totalDispatchAmount > 0)) {
          dispatchElement.textContent = dipatchAmount + 1
          totalDispatchElement.text(totalDispatchAmount + 1)
          total_scan += 1
          $('.scan-product-barcode').val('')
        }
        else {
          $('.barcode-error-alert').removeClass('d-none')
          $('.barcode-error-alert').text('This Barcode is already scanned.');
          $(".barcode-error-alert").fadeTo(4000, 500).slideUp(500, function () {
            $(".barcode-error-alert").slideUp(500);
          });
          $('.scan-product-barcode').val('')
        }
        if (total_order_amount == total_scan) {
          $('.scan-product-barcode').val('')
          $('#label-verification-modal').show()
        }
      }
      else {
        $('.barcode-error-alert').removeClass('d-none')
        $('.barcode-error-alert').text('This Barcode does not exist in this order.');
        $(".barcode-error-alert").fadeTo(4000, 500).slideUp(500, function () {
          $(".barcode-error-alert").slideUp(500);
        });
        $('.scan-product-barcode').val('')
      }
    }
  })

  $('.scan-barcode').on('keydown', function (e) {
    if (e.keyCode == 13) {
      var barcodeValues = []
      var indexOfMatched = 0;
      for (var i = 0; i < $('.barcode-values').length; i++) {
        barcodeValues.push($('.barcode-values')[i].textContent)
      }
      if (barcodeValues.includes($(this).val())) {
        indexOfMatched = barcodeValues.indexOf($(this).val());
        var tr = $('.barcode-values')[indexOfMatched].closest('tr')
        var dispatchElement = tr.querySelector('.dispatched-amount')
        var orderedElement = tr.querySelector('.ordered-amount')
        var totalDispatchElement = $('.total-dispatched-amount')
        var dipatchAmount = parseInt(dispatchElement.textContent)
        var orderedAmount = parseInt(orderedElement.textContent)
        var totalDispatchAmount = parseInt(totalDispatchElement.text())
        if (orderedAmount - dipatchAmount > 1) {
          dispatchElement.textContent = dipatchAmount + 1
          totalDispatchElement.text(totalDispatchAmount + 1)
          $('.scan-product-barcode').val('')
        }
        else {
          var barcode = $(this).val()
          var batch_id = parseInt($('.batch_id').text())
          var order_id = parseInt($('.order_id').text())
          $('#archive-modal').show()
          timer()
          setTimeout(function () {
            if (is_all_selected) {
              $('#archive-modal').hide();
              $.ajax({
                url: '/pick_and_packs/lables_scane_continue',
                type: "GET",
                data: { scan_type: 'single', barcode: barcode, batch_id: batch_id, order_id: order_id },
                success: function (response) {
                  if (response) {
                  }
                  else {
                  }
                }
              })
            }
            else {
              is_all_selected = true
            }
            $('.scan-product-barcode').val('')
          }, 5000);
        }
        $('.barcode-error-alert').addClass('d-none')
      }
      else {
        $('.barcode-error-alert').removeClass('d-none')
        $('.scan-product-barcode').val('')
      }
    }
  })

  function timer() {
    var timer2 = "0:05";
    var interval = setInterval(function () {
      var timer = timer2.split(':');
      //by parsing integer, I avoid all extra string processing
      var minutes = parseInt(timer[0], 10);
      var seconds = parseInt(timer[1], 10);
      --seconds;
      minutes = (seconds < 0) ? --minutes : minutes;
      seconds = (seconds < 0) ? 59 : seconds;
      seconds = (seconds < 10) ? '0' + seconds : seconds;
      //minutes = (minutes < 10) ?  minutes : minutes;
      if (minutes < 0) {
        clearInterval(interval);
      } else {
        $('.countdown').html(seconds);
        timer2 = minutes + ':' + seconds;
      }
    }, 1000);
  }
})