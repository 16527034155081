import $ from 'jquery'
$(document).on('turbolinks:load', function () {


  $('.save-delivery').on('click', function () {
    var trs = $('.po-table').find('tbody').find('tr')
    var check = false
    var check_quantity = false
    trs.each(function (){
      var last_quantity = $(this).find('.order_item_quantity').val()
      if(last_quantity === ''){
        check_quantity = true
      }
      if(parseFloat(last_quantity) === 0){
        check = true
      }
    })
    if ($('.text-danger').length !=0){
      alert('Please fill required details.');
    }
    else if(check_quantity){
      $('.submit-stock-manager-PO').trigger('click') // Check quantity is required
    }
    else{
      if(check){
        $('#confirm_stock_manager_modal').modal('show')
      }
      else{
        $('.submit-stock-manager-PO').trigger('click')
      }
    }
  })

  $('.book-input-po').on('click', function () {
    var trs = $('.po-table').find('tbody').find('tr')
    var check = false
    var check_quantity = false
    trs.each(function (){
      var first_quantity = $(this).find('.order_item_quantity').data('value')
      var last_quantity = $(this).find('.order_item_quantity').val()
      if(last_quantity === ''){
        check_quantity = true
      }
      if(parseFloat(first_quantity) != parseFloat(last_quantity)){
        check = true
      }
    })
    if(check_quantity){
      $('.confirm-book-input').trigger('click')
    }
    else{
      if(check){
        $('#confirm_book_input_modal').modal('show')
      }
      else{
        $('.confirm-book-input').trigger('click')
      }
    }
  })

  $('.confirm-book-input').on('click', function () {
    $('.cover-spin, .loading').removeClass('d-none')
  })

  $('.new-purchase-order').on('click', function(){
    var trs = $('.po-table tbody tr');
    if (!trs.find('.order_item_quantity').filter((index, element) => $(element).val().trim() !== '').length) {
      $('#message').removeClass('d-none');
      $('#message').text("All Quantity fields are blank, Please fill at least one.");
      return;
    }
    $('#message').addClass('d-none')
    $('#message').text("");
    var has_required_tr = false;
    var vatChanges = []
    var priceChanges = []
    var message = 'Price'

    trs.each(function(){
      var $tr = $(this);
      var sku = $tr.find('.product_sku').text()
      var qty = $tr.find('.order_item_quantity').val()
      var price = $tr.find('.order_item_price').val()
      var vat = $tr.find('.order_item_vat').val()
      var old_price = $tr.find('.order_item_price').data('value')
      var old_vat = $tr.find('.order_item_vat').data('value')

      if(qty !== '' && vat == '') {
        $tr.find('.order_item_vat').attr('required', true);
        has_required_tr = true;
      }

      if(qty !== '' && price == ''){
        $tr.find('.order_item_price').attr('required', true);
        has_required_tr = true;
      }
      if (parseFloat(price) != parseFloat(old_price)) {
        priceChanges.push({ sku: sku, oldPrice: old_price, newPrice: price });
      }
      if ((parseFloat(vat) != parseFloat(old_vat)) && (typeof old_vat != 'undefined')) {
        vatChanges.push({ sku: sku, oldVat: old_vat, newVat: vat });
      }

    });
    $('.new-purchase-order-submit').trigger('click');
    // if (!has_required_tr && (vatChanges.length != 0 || priceChanges != 0)) {
    //   if (vatChanges.length != 0 && priceChanges != 0) {
    //     message = 'Price and Vat'
    //   }
    //   else if (vatChanges.length != 0) {
    //     message = 'Vat'
    //   }
    //   $('.po-alert-msg').text(message)
    //   $('#ChangesModal').modal('show')
    //   $('.remove').remove()
    // }
    // else {
    //   $('.new-purchase-order-submit').trigger('click');
    // }
  })

  $('.order_item_price, .order_item_vat, .orders_quantity').on('change keyup', function () {
    var oldValue = $(this).data('value')
    var newValue = $(this).val()
    $(this).removeClass('bg-warning bg-danger text-white');
    if (parseFloat(newValue) != parseFloat(oldValue) && newValue !== '') {
      $(this).addClass('bg-warning');
    } else if (newValue === '') {
      $(this).addClass('bg-danger text-white');
    }
  })

})
