$(document).on('turbolinks:load', function () {
  var is_all_selected = true;
  $('.scan-product-barcode-print').on('keydown', function (e) {
    if (e.keyCode == 13 && $(this).val() != '') {
      var barcodeValues = []
      var indexOfMatched = 0;
      for (var i = 0; i < $('.barcode-values').length; i++) {
        barcodeValues.push($('.barcode-values')[i].textContent)
      }
      if (barcodeValues.includes($(this).val())) {
        indexOfMatched = barcodeValues.indexOf($(this).val());
        var tr = $('.barcode-values')[indexOfMatched].closest('tr')
        var dispatchElement = tr.querySelector('.dispatched-amount')
        var orderedElement = tr.querySelector('.ordered-amount')
        var totalDispatchElement = $('.total-dispatched-amount')
        var dipatchAmount = parseInt(dispatchElement.textContent)
        var orderedAmount = parseInt(orderedElement.textContent)
        var totalDispatchAmount = parseInt(totalDispatchElement.text())
        var totalOrderedAmount = parseInt($('.total-ordered-amount').text())
        var check = $('.print-listing')
        if (orderedAmount == dipatchAmount) {
          $('.barcode-error-alert').removeClass('d-none');
          $('.barcode-error-alert').text('This barcode is already scanned.');
          $(".barcode-error-alert").fadeTo(4000, 500).slideUp(500, function () {
            $(".barcode-error-alert").slideUp(500);
          });
          $('.scan-product-barcode-print').val('')
        }
        else {
          if (totalOrderedAmount - totalDispatchAmount > 1) {
            dispatchElement.textContent = dipatchAmount + 1
            totalDispatchElement.text(totalDispatchAmount + 1)
            $('.scan-product-barcode-print').val('')
            if ((check[0] != undefined) && (dipatchAmount + 1 == orderedAmount)) {
              $(tr).addClass('success-3px-border')
            }
          }
          else {
            // var barcode = $(this).val()
            // var batch_id = parseInt($('.batch_id').text())
            // var order_id = parseInt($('.order_id').text())
            dispatchElement.textContent = dipatchAmount + 1
            totalDispatchElement.text(totalDispatchAmount + 1)
            if (check[0] == undefined) {
              $('#print-confirmation-modal').show()
              timer()
              setTimeout(function () {
                print_labels()
              }, 5500);
            }
            else {
              $(tr).addClass('success-3px-border')
            }
          }
        }
        // $('.barcode-error-alert').addClass('d-none')
      }
      else {
        $('.barcode-error-alert').removeClass('d-none')
        $('.barcode-error-alert').text('This Barcode does not exist in this order.');
        $(".barcode-error-alert").fadeTo(4000, 500).slideUp(500, function () {
          $(".barcode-error-alert").slideUp(500);
        });
        $('.scan-product-barcode-print').val('')
      }
    }
  })

  $('.scan-all-orders-yes').on('click', function () {
    var barcode = $('.scan-product-barcode-print').val()
    var scanned_items = $('.scanned-items').text()
    var batch_id = parseInt($('.batch_id').text())
    var order_id = parseInt($('.order_id').text())
    is_all_selected = false;
    $('#print-confirmation-modal').hide()
    $.ajax({
      url: '/pick_and_packs/labels_scan_continue',
      type: "GET",
      data: { scan_type: 'multiple', barcode: barcode, batch_id: batch_id, order_id: order_id, scanned_items: scanned_items },
      success: function (response) {
        if (response) {
          if (response.order_completed) {
            $('.barcode-error-alert').text(response.message);
            $('.barcode-error-alert').removeClass('bg-danger').addClass('bg-success')
            window.location = response.redirect_uri
          }
          else {
            $('.job_status_id').text(response.job_id)
          }
        }
        else {
        }
      }
    })
    $('.scan-product-barcode-print').val('')
  })

  $('.scan-all-orders-no').on('click', function () {
    is_all_selected = false;
    $('#print-confirmation-modal').hide()
    $('.scan-product-barcode-print').val('')
  })

  function timer() {
    var counter = 5;
    var interval = setInterval(function () {
      $('.countdown').html(counter);
      counter--;
      if (counter < 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  function print_labels() {
    if (is_all_selected) {
      var barcode = $('.scan-product-barcode-print').val()
      var scanned_items = $('.scanned-items').text()
      var batch_id = parseInt($('.batch_id').text())
      var order_id = parseInt($('.order_id').text())
      $('#print-confirmation-modal').hide();
      $.ajax({
        url: '/pick_and_packs/labels_scan_continue',
        type: "GET",
        data: { scan_type: 'single', barcode: barcode, batch_id: batch_id, order_id: order_id, scanned_items: scanned_items },
        success: function (response) {
          if (response) {
            if (response.order_completed) {
              $('.barcode-error-alert').text(response.message);
              $('.barcode-error-alert').removeClass('bg-danger').addClass('bg-success')
              window.location = response.redirect_uri
            }
            else {
              $('.job_status_id').text(response.job_id)
            }
          }
        }
      })
    }
    else {
      is_all_selected = true
    }
    $('.scan-product-barcode-print').val('')
  }

  $('.print-confirmation-modal').on('click', function () {
    var total_orders = $('.total-orders-number').text()
    this.disabled = true;
    if (total_orders > 1) {
      $('#print-confirmation-modal').show()
      timer()
      setTimeout(function () {
        print_labels()
      }, 5500);
    }
    else {
      print_labels()
    }
  })

  $('.print-confirmation-fba-modal').on('click', function () {
    var order_details = $('.fba-order-detail').length
    var trs = $('tr.success-3px-border').length
    if (order_details == trs) {
      this.disabled = true;
      $('.cover-spin, .loading').removeClass('d-none')
      print_labels()
    }
    else {
      $('.barcode-error-alert').removeClass('d-none');
      $('.barcode-error-alert').text('Please scan complete listing products.');
      $(".barcode-error-alert").fadeTo(4000, 500).slideUp(500, function () {
        $(".barcode-error-alert").slideUp(500);
      });
    }
  })

  $('.scan-fba-barcode-print').on('keydown', function (e) {
    if (e.keyCode == 13 && $(this).val() != '') {
      var barcodeValues = []
      var indexOfMatched = 0;
      for (var i = 0; i < $('.barcode-values').length; i++) {
        barcodeValues.push($('.barcode-values')[i].textContent)
      }
      if (barcodeValues.includes($(this).val())) {
        indexOfMatched = barcodeValues.indexOf($(this).val());
        var tr = $('.barcode-values')[indexOfMatched].closest('tr')
        var dispatchElement = tr.querySelector('.dispatched-amount')
        var orderedElement = tr.querySelector('.ordered-amount')
        var totalDispatchElement = $('.total-dispatched-amount')
        var dipatchAmount = parseInt(dispatchElement.textContent)
        var orderedAmount = parseInt(orderedElement.textContent)
        var totalDispatchAmount = parseInt(totalDispatchElement.text())
        var totalOrderedAmount = parseInt($('.total-ordered-amount').text())
        if (orderedAmount == dipatchAmount) {
          $('.barcode-error-alert').removeClass('d-none');
          $('.barcode-error-alert').text('This barcode is already scanned.');
          $(".barcode-error-alert").fadeTo(4000, 500).slideUp(500, function () {
            $(".barcode-error-alert").slideUp(500);
          });
          $('.scan-fba-barcode-print').val('')
        }
        else {
          dispatchElement.textContent = dipatchAmount + 1
          totalDispatchElement.text(totalDispatchAmount + 1)
          var barCount = parseInt($('.' + $(this).val()).val()) || 0
          $('.' + $(this).val()).val(barCount + 1)
          $('.scan-fba-barcode-print').val('')
        }
      }
      else {
        $('.barcode-error-alert').removeClass('d-none')
        $('.barcode-error-alert').text('This Barcode does not exist in this order.');
        $(".barcode-error-alert").fadeTo(4000, 500).slideUp(500, function () {
          $(".barcode-error-alert").slideUp(500);
        });
        $('.scan-fba-barcode-print').val('')
      }
    }
  })
})
