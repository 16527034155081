import $ from 'jquery'
$(document).on('turbolinks:load', function () {
  $('#select-all-asin').on('click', function () {
    var switches = $('input[name="select-custom-asin"]');
    if(this.checked){
      switches.prop("checked", true);
    }
    else{
      switches.prop("checked", !switches.is(":checked"));
    }
  })

  $('.refetch-selected, .delete-selected, .refetch-all').on('click', function () {
    var check = this['dataset']['status']
    var btn = $("input[value='refetch_all']")
    btn.attr('value', check).trigger('click')
  })

  $('.rename-all').on('click', function () {
    var check = this['dataset']['status']
    var btn = $("input[value='Rename']")
    btn.attr('value', check)
  })

  $('#export-asin-tracker').on('click', function () {
    $('.trigger-asin-tracker').trigger('click')
  })

  $('.export-manual-edit-report').on('click', function(){
    $('.trigger-manual-edit').trigger('click')
  })

  $('.order-status-report').on('change', function () {
    $('.submit-report').trigger('click')
  })
  if ($('div').hasClass('download-reports-page')) {
    setTimeout(function(){
      location.reload();
    }, 30000);
  }
})
