import $ from 'jquery'

$(document).ready(function () {
  if ($('#ErrorsProduct') == 'nul') {
    $('#single-product-create-modal').modal('show')
  }
}, 5000);

$(document).on('turbolinks:load', function () {

  $('input, select').on('click', function () {
      $(this).removeClass('border-danger');
  })
  // Product Create Form JQuery start
  $('.multi_basic_info_button').on('click', function () {
    // var photo = $('#multi-product-create-modal input[name="product[photo]"]').val()
    var title = $('#multi-product-create-modal input[name="product[title]"]').val()
    var sku = $('#multi-product-create-modal input[name="product[sku]"]').val()
    var category = $('#multi-product-create-modal input[name="category_name"]').val()
    // var product_type = $('#multi-product-create-modal select[name="product[product_type]"').val()
    if ((title != "") && (sku != "")) {
      $('.multi-basic-info-form').addClass('d-none')
      $('.multi-detail-form').removeClass('d-none')
      $('.create_product_button').removeClass('d-none')
    }
    else {
      if (title == "") {
        $('#multi-product-create-modal input[name="product[title]"]').addClass('border border-danger');
      }
      if (sku == "") {
        $('#multi-product-create-modal input[name="product[sku]"]').addClass('border border-danger');
      }
      // if (category == "") {
      //   $('#multi-product-create-modal input[name="category_name"]').addClass('border border-danger');
      // }
    }
  })

  $('.multi_detail_info_button').on('click', function () {
    $('.multi-detail-form').addClass('d-none')
    $('.multi-basic-info-form').removeClass('d-none')
    $('.create_product_button').addClass('d-none')
  })

  $('.basic_info_button').on('click', function () {
    // var photo = $('input[name="product[photo]"]').val()
    var title = $('input[name="product[title]"]').val()
    var sku = $('input[name="product[sku]"]').val()
    var category = $('input[name="category_name"]').val()
    // var product_type = $('select[name="product[product_type]"]').val()
    if ((title != "") && (sku != "") && (category != "")) {
      $('.basic-info-form').addClass('d-none')
      $('.detail-form').removeClass('d-none')
    }
    else {
      if (title == "") {
        $('#single-product-create-modal input[name="product[title]"]').addClass('border border-danger');
      }
      if (sku == "") {
        $('#single-product-create-modal input[name="product[sku]"]').addClass('border border-danger');
      }
      if (category == "") {
        $('#single-product-create-modal input[name="category_name"]').addClass('border border-danger');
      }
    }
  })

  $('.detail_button_previous').on('click', function () {
    $('.detail-form').addClass('d-none')
    $('.basic-info-form').removeClass('d-none')

  })

  $('.detail_button').on('click', function () {
    var length = $('input[name="product[length]"]').val()
    var width = $('input[name="product[width]"]').val()
    var height = $('input[name="product[height]"]').val()
    var weight = $('input[name="product[weight]"]').val()
    if ((length != "") && (width != "") && (height != "") && (weight != "")) {
      $('.detail-form').addClass('d-none')
      $('.stock-form').removeClass('d-none')
      $('.create_product_button').removeClass('d-none')
    }
    else {
      if (length == "") {
        $('input[name="product[length]"]').addClass('border border-danger');
      }
      if (width == "") {
        $('input[name="product[width]"]').addClass('border border-danger');
      }
      if (height == "") {
        $('input[name="product[height]"]').addClass('border border-danger');
      }
      if (weight == "") {
        $('input[name="product[weight]"]').addClass('border border-danger');
      }
    }
  })

  $('.stock_button_previous').on('click', function () {
    $('.stock-form').addClass('d-none')
    $('.detail-form').removeClass('d-none')
    $('.detail-tax-field').addClass('d-none')
  })

  $('.stock_button').on('click', function () {
    var total_stock = $('input[name="product[total_stock]"]').val()
    var vat = $('input[name="product[vat]"]').val()
    var supplier = $('select[name="product[product_suppliers_attributes][1][system_user_id]"]').val()
    if ((total_stock != "") && (vat != "") && (supplier != "")) {
      $('.stock-form').addClass('d-none')
      $('.extra-form').removeClass('d-none')
      $('.extra-stock-field').removeClass('d-none')
      $('.extra-pack-field').removeClass('d-none')
    }
    else {
      if (total_stock == "") {
        $('input[name="product[total_stock]"]').addClass('border border-danger');
      }
      if (vat == "") {
        $('input[name="product[vat]"]').addClass('border border-danger');
      }
      if (supplier == "") {
        $('select[name="product[product_suppliers_attributes][1][system_user_id]"]').addClass('border border-danger');
      }
    }
  })

  $('.stock_button_previous').on('click', function () {
    $('.stock-form').addClass('d-none')
    $('.detail-form').removeClass('d-none')
    $('.create_product_button').addClass('d-none')
  })

  $('.extra_button_previous').on('click', function () {
    $('.extra-form').addClass('d-none')
    $('.stock-form').removeClass('d-none')
  })

  $('.extra_feild').on('click', function(){
    $('.stock-form').addClass('d-none')
    $('.extra-field-form').removeClass('d-none')
    $('.create_product_button').removeClass('d-none')
  })


  $('.extra_button_previous').on('click', function(){
    var total_stock = $('input[name="product[total_stock]"]').val()
    var vat = $('input[name="product[vat]"]').val()
    var supplier = $('select[name="product[product_suppliers_attributes][1][system_user_id]"]').val()
    if ((total_stock != "") && (vat != "") && (supplier != "")) {
      $('.stock-form').removeClass('d-none')
      $('.extra-field-form').addClass('d-none')
      $('.create_product_button').addClass('d-none')
      $('.create_product_button').removeClass('d-none')
    }
    else {
      if (total_stock == "") {
        $('input[name="product[total_stock]"]').addClass('border border-danger');
      }
      if (vat == "") {
        $('input[name="product[vat]"]').addClass('border border-danger');
      }
      if (supplier == "") {
        $('select[name="product[product_suppliers_attributes][1][system_user_id]"]').addClass('border border-danger');
      }
    }
  })

  // $('#single-product-create-modal input[type="submit"]').on('click', function () {
  //   var season = $('select[name="product[season_id]"]').val()
  //   if ((season != "")) {
  //     $('.create-single-product').trigger('click')
  //   }
  //   else {
  //     if (season == "") {
  //       $('#single-product-create-modal #product_season_id_chosen').css({'border': '1px solid red', 'border-radius': '6px'});
  //     }
  //   }
  // })
  // Product Create Form JQuery end

  $(".nested_fields_btn").
    data("association-insertion-method", 'after')

  $('.product-type-field').on('change', function () {
    $('.product_type_submit').trigger('click')
  })

  $('.bulk-destroy-objects').on('click', function () {
    $('.bulk-method-destroy-objects').trigger('click')
  })

  window.update_selected = function (url, class_name, event) {
    var target = event.currentTarget
    var value = target.checked
    var id = target.id

    if (typeof class_name === 'string' && typeof value === 'boolean' && typeof id === 'string' && typeof url === 'string'){
      $.ajax({
        url: url,
        type: "GET",
        data: { 'selected': value, 'id': id, class_name: class_name },
        success: function (response) {
          if (response.result) {
            // $('.jquery-selected-alert').html('Object updated successfully')
            // $('.jquery-selected-alert').addClass('bg-success').removeClass('d-none').removeClass('bg-danger')
            // $(".jquery-selected-alert").fadeTo(2000, 500).slideUp(500, function () {
            //   $(".jquery-selected-alert").slideUp(500);
            // });
          }
          else{
            // $('.jquery-selected-alert').html('Object cannot be updated! '+ response.errors[0])
            // $('.jquery-selected-alert').addClass('bg-danger').removeClass('d-none')
            // $('.jquery-selected-alert').alert('show')
            // $(".jquery-selected-alert").fadeTo(2000, 500).slideUp(500, function () {
            //   $(".jquery-selected-alert").slideUp(500);
            // });
          }
        }
      })
    }
    else {
      // $('.jquery-selected-alert').html('Cannot update object, please refresh and try again.')
      // $('.jquery-selected-alert').addClass('bg-danger').removeClass('d-none')
      // $(".jquery-selected-alert").fadeTo(2000, 500).slideUp(500, function () {
      //   $(".jquery-selected-alert").slideUp(500);
      // });
    }
  }

  window.bulk_update_selected = function (url, class_name, event) {
    var target = event.target
    var switches = $('input[name="select-switch"]');
    switches.prop("checked", target.checked);
    var selected = []
    var unselected = []
    switches.each(function () {
      if (this.checked) {
        selected.push(this.id)
      }
      else {
        unselected.push(this.id)
      }
    })

    if (typeof class_name === 'string' && (selected.length + unselected.length > 0) && typeof url === 'string') {
      $.ajax({
        url: url,
        type: 'GET',
        data: { 'selected': selected, 'unselected': unselected, class_name: class_name },
        success: function (response) {
          if (response.result) {
            // $('.jquery-selected-alert').html('All objects updated successfully')
            // $('.jquery-selected-alert').addClass('bg-success').removeClass('d-none').removeClass('bg-danger')
            // $(".jquery-selected-alert").fadeTo(2000, 500).slideUp(500, function () {
            //   $(".jquery-selected-alert").slideUp(500);
            // });
          }
        }
      })
    }
    else {
      // $('.jquery-selected-alert').html('Cannot update object, please refresh and try again.')
      // $('.jquery-selected-alert').addClass('bg-danger').removeClass('d-none')
      // $(".jquery-selected-alert").fadeTo(2000, 500).slideUp(500, function () {
      //   $(".jquery-selected-alert").slideUp(500);
      // });
    }
  }

  $('.reason-get-button').on('click', function () {
    $('.update_fake_stock').val($('.updating_fake_stock').val());
    $('.update_stock').val($('.updating_stock').val());
    $('.reason-modal').modal('show');
  })

  $('.export-all').on('click', function () {
    $('.bulk-method-export-all-objects').trigger('click');
  })

  $('.export-selected').on('click', function () {
    $('.bulk-method-export-selected-objects').trigger('click');
  })

  $('.edit-disable').click(function(event){
    event.preventDefault();
    if (!$('.edit-disable').attr("class").includes('disabled')) {
      var class_name = "remove-disable" + event.target.id + 'a';
      var class_name_1 = "remove-disable" + event.target.id + 'b';
      $('.'+class_name).prop("disabled", false);
      $('.'+class_name_1).prop("disabled", false);
      $('.'+class_name).removeClass('bg-white border-0')
      $('.add-location')[0].classList.add('disabled');
      $('.add-location').parent().addClass('cursor-not-allowed-pointer')
      $('.set-default-location, .transfer-stock').prop('disabled', true);
      $('.set-default-location, .transfer-stock').addClass('cursor-not-allowed-pointer')
      $("input[id=change_stock]").val('true');
    }
  });

  $('.transfer-stock').click(function(event){
    event.preventDefault();
    $('.edit-disable').addClass('disabled cursor-not-allowed-pointer');
    $('.set-default-location').prop('disabled', true);
    $('.set-default-location').addClass('cursor-not-allowed-pointer')
    $('.add-location')[0].classList.add('disabled');
    $('.add-location').parent().addClass('cursor-not-allowed-pointer')
  });

  $('.add-location').click(function(event){
    event.preventDefault();
    $('.edit-disable').addClass('disabled cursor-not-allowed-pointer');
    $('.set-default-location, .transfer-stock').prop('disabled', true);
    $('.set-default-location, .transfer-stock').addClass('cursor-not-allowed-pointer')
    $('.save-changes').addClass('d-none')
    $('.add-location-submit').removeClass('d-none')
    $('select[name="reason"], textarea[name="description"]').removeAttr('required')
  });

  $('.set-default-location').click(function(event){
    event.preventDefault();
    $('.edit-disable').addClass('disabled cursor-not-allowed-pointer');
    $('.add-location')[0].classList.add('disabled');
    $('.add-location').parent().addClass('cursor-not-allowed-pointer')
    $('.transfer-stock').prop('disabled', true);
    $('.transfer-stock').addClass('cursor-not-allowed-pointer')
  });

  $('.add-location, .edit-disable').click(function(event){
    event.preventDefault();
    $('.save-changes').prop('disabled', false);
    $('.save-changes').removeClass('cursor-not-allowed-pointer')
  });

  $('#q_g_0_c_0_a_0_name').on('change', function () {
    if (['weight', 'total_stock', 'rank', 'monthly_sales', 'monthly_revenue', 'minimum', 'landed_price', 'buybox_price'].includes(this.value) ){
      $('#q_g_0_c_0_p option:selected').attr('selected', false)
      $('#q_g_0_c_0_p option[value="eq"]').attr('selected', 'selected')
    }
    else {
      $('#q_g_0_c_0_p option:selected').attr('selected', false)
      $('#q_g_0_c_0_p option[value="cont"]').attr('selected', 'selected')
    }
  })

  $('#select_all').on('click', function(){
    if($(this).prop("checked") == true) {
      $('#date').prop("checked", true);
      $('#time').prop("checked", true);
      $('#actions').prop("checked", true);
      $('#system_id').prop("checked", true);
      $('#channel_id').prop("checked", true);
      $('#channel_item_id').prop("checked", true);
      $('#change_inventory_balance').prop("checked", true);
      $('#inventory_balance').prop("checked", true);
      $('#warehouse_stock').prop("checked", true);
      $('#unallocated').prop("checked", true);
      $('#allocated').prop("checked", true);
      $('#user_name').prop("checked", true);
      $('#unshipped').prop("checked", true);
      $('#change_warehouse_stock').prop("checked", true);
    }
    else{
      $('#date').prop("checked", false);
      $('#time').prop("checked", false);
      $('#actions').prop("checked", false);
      $('#system_id').prop("checked", false);
      $('#channel_id').prop("checked", false);
      $('#channel_item_id').prop("checked", false);
      $('#change_inventory_balance').prop("checked", false);
      $('#inventory_balance').prop("checked", false);
      $('#warehouse_stock').prop("checked", false);
      $('#unallocated').prop("checked", false);
      $('#allocated').prop("checked", false);
      $('#user_name').prop("checked", false);
      $('#unshipped').prop("checked", false);
      $('#change_warehouse_stock').prop("checked", false);
    }
  })

  $('.change-is-default').on('click', function () {
    var confirmMsg = "Do you want to update the default location?";
    if (confirm(confirmMsg)) {
      $('.edit_product input[name="set_default_location"]').val('true');
      $('.edit_product').submit();
      $.ajax({
        type: 'PUT',
        url: $('.edit_product').attr('action'),
        data: $('.edit_product').serialize(),
        success: function(response) {
          location.reload();
        },
        error: function() {
          alert('An error occurred while updating product location');
        }
      });
    }
  });

  $('.default-radio-button').on('click', function () {
    if (confirm("Do you want to update the default location?")) {
      $('#reason').removeAttr('required');
      $('#description').removeAttr('required');
      $(this).closest('.form-group').append('<input type="hidden" name="set_default_location" value="true">');
      document.querySelector("#default-location-submit").click();
    }
  });

  $('.rename-location').on('click', function () {
    var locationId = $(this).data('location-id');
    var locationName = $('#location-' + locationId).val();
    var stockValue = $(this).closest('.form-group').find("input[type='number']").val();

    $('#from-location-name').text(locationName);
    $('#from_location_id').val(locationId);
    $('#stock_value').val(stockValue);

    $('#to').val('').trigger('change');
    $('#transfer-location').modal('show');
  });

})
