import $ from 'jquery'

$(document).on('turbolinks:load', function () {
  $('.allocate-button').on('click', function () {
    $('.bulk-allocation').trigger('click')
  })

  function processOrders(object_ids, callback) {
    var pregenerated_labels = object_ids.map(function () { return this.dataset.pregenerated_label }).toArray();
    if (pregenerated_labels.includes('true')) {
      $('.cover-spin, .loading').addClass('d-none');
      $('.jquery-selected-alert').html('Cannot change rule of Pregenerated Label!');
      $('.jquery-selected-alert').addClass('bg-danger').removeClass('d-none').removeClass('bg-success');
      $(".jquery-selected-alert").fadeTo(4000, 500).slideUp(500, function () {
        $(".jquery-selected-alert").slideUp(500);
      });
      return;
    }
    var order_ids = object_ids.map(function (i, e) { return e.value }).toArray();
    callback(order_ids);
  }

  $('.re-calculate-rules').on('click', function () {
    var object_ids = $('input[name="object_ids[]"]:checked');
    processOrders(object_ids, function (order_ids) {
      $.ajax({
        url: '/order_dispatches/recalculate_rule',
        type: 'GET',
        data: { 'orders': order_ids },
        success: function (response) {
          window.location.reload();
        }
      });
    });
  });

  $('.bulk-assign-rule').on('click', function () {
    var object_ids = $('input[name="object_ids[]"]:checked');
    processOrders(object_ids, function (order_ids) {
      $('#bulk-mail-service-modal').modal('show');
      $('.assign_bulk_rule_button').on('click', function () {
        var rule_id = $('#rule_id').val();
        $.ajax({
          url: '/order_dispatches/bulk_assign_rule',
          type: 'GET',
          data: { 'orders': order_ids, 'rule': rule_id },
          success: function (response) {
            window.location.reload();
          }
        });
      });
    });
  });

  $(document).on('click', '.update-rule-modal', function () {
    var order_id = this.dataset.id
    $('#update_channel_order_id').val(order_id)
    $('#mail-service-roles-update-modal-' + order_id).modal('show')
  })


  $('.tracking-form').on('submit', function(){
    $('.without_tracking_btn').removeClass('d-none')
  })

  $('#condition').on('change', function () {
    $(this).closest('form').submit();
  });

  $('.edit_assign_rule').on('keypress', function (e) {
    if (e.which === 13){
      $(this).find('.create_service_rule_button').trigger('click')
      return false;
    }
  })

  $('.pregenerate-labels').on('click', function () {
    var object_ids = $('input[name="object_ids[]"]:checked')
    var order_ids = object_ids.map(function (i, e) { return e.value }).toArray();
    var count = 0
    object_ids.map(function (i, e) { return count = count + parseInt(e.dataset.item) })
    var rule = object_ids.map(function (i, e) { return e.dataset.courier }).toArray();
    var pregenerate_rule = rule.every((val, i, arr) => val === arr[0]) && rule.length > 0 && (rule[0] == 'ITD' || rule[0].toLowerCase().includes('amazon prime'))
    var order_status = object_ids.map(function (i, e) { return e.dataset.status }).toArray();

    if ($.inArray('In Progress', order_status) > -1) {
      if (confirm('One of your order is already in progress. Would you like continue?')) {
      } else {
        return
      }
    }
    if (!pregenerate_rule) {
      $('.cover-spin, .loading').addClass('d-none')
      $('.jquery-selected-alert').html('Please pregenerate only ITD or prime orders.')
      $('.jquery-selected-alert').addClass('bg-danger').removeClass('d-none').removeClass('bg-success')
      $(".jquery-selected-alert").fadeTo(4000, 500).slideUp(500, function () {
        $(".jquery-selected-alert").slideUp(500);
      });
    }
    else if (object_ids.length > 0) {
      $.ajax({
        url: '/order_batches/pregenerate_label',
        type: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: { 'ids': order_ids },
        dataType: 'json',
        success: function () {
          $('input[type="checkbox"]:checked').prop('checked', false);
          $('.cover-spin, .loading').addClass('d-none')
          $('.jquery-selected-alert').html('Labels will be pregenerated shortly')
          $('.jquery-selected-alert').addClass('bg-success').removeClass('d-none').removeClass('bg-danger')
          $(".jquery-selected-alert").fadeTo(4000, 500).slideUp(500, function () {
            $(".jquery-selected-alert").slideUp(500);
          });
        }
      })
    }
    else {
      $('.jquery-selected-alert').html('Please select orders first!')
      $('.jquery-selected-alert').addClass('bg-danger').removeClass('d-none').removeClass('bg-success')
      $(".jquery-selected-alert").fadeTo(4000, 500).slideUp(500, function () {
        $(".jquery-selected-alert").slideUp(500);
      });
    }
  })

  $('.shipment-order').on('click', function () {
    var inputValues = {}
    $('input[type="number"]').each(function () {
      var inputValue = $(this).val();
      var inputName = $(this).attr('name');

      if (inputValue !== '') {
        inputValues[inputName] = inputValue;
      }
    });
    $.ajax({
      url: '/fba_shipments/check_available_stock',
      data: { 'input_values': inputValues },
      type: 'GET',
      success: function (response) {
        if (response.message != '') {
          $('#order-alert-modal').modal('show')
          $('.alert-message').html(response.message)
        }
        else {
          $('#new_channel_order')[0].submit()
        }
      }
    })
  }
  )

})
